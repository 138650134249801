export const CODES = {
  USER_NOT_CONFIRMED: 'a001',
  ACCESS_RESTRICTED: 'a002',
  USER_NOT_FOUND: 'a003',
  INCORRECT_EMAIL: 'a004',
  INCORRECT_DATA: 'a005',
  RETRY_REGISTRATION_LIMIT: 'a006',
  ALREADY_REGISTERED: 'a007',
  TECHNICAL_ISSUES_BACK: 'a500',
  TECHNICAL_ISSUES_RETRY: 'b500',
  USER_HAS_NO_ACCESS_TO_APP: 'b403',
  ROOTED_DEVICE: 'n001',
  OUTDATED_VERSION: 'n002',
  VIRTUAL_DEVICE: 'n003',
  BROWSER_DEVICE: 'n004',
  OUTDATED_WEB_VERSION: 'n005',
};
