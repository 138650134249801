import { Capacitor } from '@capacitor/core';

const checkIsRooted = () => {
  return new Promise((resolve, reject) => {
    if (Capacitor.getPlatform() === 'web') {
      resolve(false);
    } else {
      (window as any).cordova.exec(resolve, reject, 'IRoot', 'isRooted', []);
    }
  });
};

export default checkIsRooted;
