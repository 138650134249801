import { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';
import { t } from 'tools/i18n';

import { Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import s from './surveyLabel.module.scss';

export enum LABEL_TYPES {
  COMPLETED = 'completed',
  MANDATORY = 'mandatory',
}

interface SurveysLabelProps extends PropsWithChildren {
  type: LABEL_TYPES;
}

type ComponentType = {
  icon: TIconsLiteral;
  text: string;
};

const componentsData: Record<SurveysLabelProps['type'], ComponentType> = {
  [LABEL_TYPES.COMPLETED]: {
    icon: 'ok',
    text: t('survey_label_passed'),
  },
  [LABEL_TYPES.MANDATORY]: {
    icon: 'time',
    text: t('survey_label_mandatory'),
  },
};

const SurveyLabel: FC<SurveysLabelProps> = (props) => {
  const { type, children } = props;

  return (
    <div className={classNames(s.container, s[type])}>
      <Icon name={componentsData[type].icon} />
      <Typography.Text size="12" weight="500">
        {componentsData[type].text} {children}
      </Typography.Text>
    </div>
  );
};

export default SurveyLabel;
