import { lazy } from 'react';

import { RouteConfig } from '../routes';
import {
  GUIDE_ACTIVATE_SETTLEMENT_BY_ID,
  GUIDE_BY_ID_ROUTE,
  GUIDE_GUIDE_SETTLEMENT_POSTER_DETAILS_ROUTE,
  GUIDE_IMPORTANT_CONTACTS_ROUTE,
  GUIDE_MANAGEMENT_ROUTE,
  GUIDE_MAP_ROUTE,
  GUIDE_MEMO_ROUTE,
  GUIDE_MY_SETTLEMENTS_ROUTE,
  GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE,
  GUIDE_QR_CODE_ROUTE,
  GUIDE_ROUTE,
  GUIDE_SELECT_SETTLEMENT_ROUTE,
  GUIDE_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE,
  GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_FROM_DRAFT_ROUTE,
  GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_ROUTE,
  GUIDE_SOCIAL_POLICY_PROGRAM_DETAILS_ROUTE,
  GUIDE_SOCIAL_POLICY_ROUTE,
} from './list';

const Guide = lazy(() => import('pages/guide'));
const ActivateSettlementById = lazy(
  () => import('pages/guide/containers/activate-settlement-by-id'),
);
const GuideImportantContacts = lazy(() => import('pages/guide/guide-important-contacts'));
const GuideManagement = lazy(() => import('pages/guide/guide-management'));
const GuideMap = lazy(() => import('pages/guide/guide-map'));
const GuideMemo = lazy(() => import('pages/guide/guide-memo'));
const GuideMyCamp = lazy(() => import('pages/guide/guide-my-camp'));
const GuideMySettlements = lazy(() => import('pages/guide/guide-my-settlements'));
const GuideQR = lazy(() => import('pages/guide/guide-qr'));
const GuideSelectSettlement = lazy(() => import('pages/guide/guide-select-settlement'));
const GuideSettlementPosterDetails = lazy(
  () => import('pages/guide/guide-settlement/guide-settlement-poster-details'),
);
const GuideSocialPolicy = lazy(() => import('pages/guide/guide-social-policy'));
const GuideSocialPolicyApply = lazy(
  () => import('pages/guide/guide-social-policy/guide-social-policy-apply'),
);
const GuideSocialPolicyCategory = lazy(
  () => import('pages/guide/guide-social-policy/guide-social-policy-category'),
);
const GuideSocialPolicyCategoryProgram = lazy(
  () =>
    import(
      'pages/guide/guide-social-policy/guide-social-policy-category/guide-social-policy-category-program'
    ),
);

export const guideRoutes: RouteConfig[] = [
  {
    path: GUIDE_ROUTE,
    element: <Guide />,
  },
  {
    path: GUIDE_BY_ID_ROUTE,
    element: <Guide />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_ROUTE,
    element: <GuideSocialPolicy />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE,
    element: <GuideSocialPolicyCategory />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_PROGRAM_DETAILS_ROUTE,
    element: <GuideSocialPolicyCategoryProgram />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_ROUTE,
    element: <GuideSocialPolicyApply />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_FROM_DRAFT_ROUTE,
    element: <GuideSocialPolicyApply />,
  },
  {
    path: GUIDE_MANAGEMENT_ROUTE,
    element: <GuideManagement />,
  },
  {
    path: GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE,
    element: <GuideMyCamp />,
  },
  {
    path: GUIDE_MEMO_ROUTE,
    element: <GuideMemo />,
  },
  {
    path: GUIDE_MAP_ROUTE,
    element: <GuideMap />,
  },
  {
    path: GUIDE_IMPORTANT_CONTACTS_ROUTE,
    element: <GuideImportantContacts />,
  },
  {
    path: GUIDE_MY_SETTLEMENTS_ROUTE,
    element: <GuideMySettlements />,
  },
  {
    path: GUIDE_QR_CODE_ROUTE,
    element: <GuideQR />,
  },
  {
    path: GUIDE_SELECT_SETTLEMENT_ROUTE,
    element: <GuideSelectSettlement />,
  },
  {
    path: GUIDE_GUIDE_SETTLEMENT_POSTER_DETAILS_ROUTE,
    element: <GuideSettlementPosterDetails />,
  },
  {
    path: GUIDE_ACTIVATE_SETTLEMENT_BY_ID,
    element: <ActivateSettlementById />,
  },
];
