import { createSlice } from '@reduxjs/toolkit';

export const SLICE_NAME = 'appSlice';

export type AppSliceState = {
  buildExpired: boolean;
};

const initialState: AppSliceState = {
  buildExpired: false,
};

export const appSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setBuildExpired(state, action) {
      state.buildExpired = action.payload;
    },
  },
});

export const { setBuildExpired } = appSlice.actions;

export default appSlice.reducer;
