import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';

import { storedValueSelector } from 'selectors/secureStorageSlice.selector';
import { setValuesToReduxSecureStorageClone } from 'slices/secureStorageSlice';
import { useAppDispatch, useAppSelector } from 'store';

import secureStorage from './secureStorage';

const useSecureStorage = <T>(key: string, initValue: T): [T, Dispatch<SetStateAction<T>>] => {
  const initRefValue = useRef<T>(initValue);
  const storedValue = useAppSelector(storedValueSelector(key));
  const dispatch = useAppDispatch();

  const value = useMemo(() => storedValue ?? initRefValue.current, [storedValue]);

  const setValue = useCallback<Dispatch<SetStateAction<T>>>(
    async (newValue) => {
      try {
        const baseNewValue = newValue ?? initRefValue.current;

        dispatch(setValuesToReduxSecureStorageClone({ key, value: baseNewValue }));
        secureStorage.setItem(key, JSON.stringify(baseNewValue));
      } catch {
        console.error(`Can not save ${key} `);
      }
    },
    [dispatch, key],
  );

  return [value, setValue];
};

export default useSecureStorage;
