import { useEffect } from 'react';

const useLoadYandexMapHook = () => {
  const loadYandexMapScript = (apiKey: string) => {
    const script = document.createElement('script');
    script.src = `https://api-maps.yandex.ru/v3/?apikey=${apiKey}&lang=ru_RU`;
    script.async = true;
    document.head.appendChild(script);
  };

  useEffect(() => {
    const apiKey = process.env.745d35a1-50d1-4979-9f54-3975642081de;

    if (apiKey) {
      loadYandexMapScript(apiKey);
    } else {
      console.error('API ключ для Yandex Maps не найден');
    }
  }, []);
};

export default useLoadYandexMapHook;
