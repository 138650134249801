import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './Header.module.scss';

interface HeaderProps extends PropsWithChildren {
  className?: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { className, children } = props;

  return <div className={cn(s.wrapper, className)}>{children}</div>;
};

export default Header;
