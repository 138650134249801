import { t } from 'tools/i18n';

const AUDIT_EVENTS = {
  AUTHORIZATION: {
    BIOMETRY: {
      SUCCESS: t('audit_authorization_biometry_success'),
      ERROR: t('audit_authorization_biometry_error'),
    },
    PASSCODE: {
      SUCCESS: t('audit_authorization_passcode_success'),
      ERROR: t('audit_authorization_passcode_error'),
    },
  },
  SETUP: {
    BIOMETRY: {
      SUCCESS: t('audit_setup_biometry_success'),
      ERROR: '',
    },
    PASSCODE: {
      SUCCESS: t('audit_setup_passcode_success'),
      ERROR: '',
    },
  },
  PERMISSIONS: {
    LOCATION: {
      SUCCESS: t('audit_permissions_location_success'),
      ERROR: t('audit_permissions_location_success'),
    },
    CAMERA: {
      SUCCESS: t('audit_permissions_camera_success'),
      ERROR: t('audit_permissions_camera_success'),
    },
  },
  CLICK: {
    ESIA: {
      SUCCESS: t('audit_click_esia_success'),
      ERROR: '',
    },
  },
  GEOLOCATION: {
    DEFINED: {
      SUCCESS: t('audit_geolocation_defined_success'),
      ERROR: '',
    },
  },
};

export default AUDIT_EVENTS;
