import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { AsyncStorage } from 'oidc-client-ts';

class SecureStorage implements AsyncStorage {
  get length() {
    return new Promise<number>((resolve) => {
      SecureStoragePlugin.keys().then(({ value }) => {
        resolve(value.length);
      });
    });
  }

  async clear() {
    return new Promise<void>(async (resolve) => {
      await SecureStoragePlugin.clear();
      resolve();
    });
  }

  async getItem(key: string) {
    return decodeURIComponent((await SecureStoragePlugin.get({ key })).value);
  }

  async getAllItems() {
    const result: Record<string, any> = {};

    try {
      await Promise.all(
        (
          await SecureStoragePlugin.keys()
        ).value.map(async (key) => {
          result[key] = parseJSON(await this.getItem(key));
        }),
      );
    } catch (error) {
      console.error(`Cannot get all items from storage`);
    }

    return result;
  }

  async key(index: number) {
    return (await SecureStoragePlugin.keys()).value[index];
  }

  removeItem(key: string) {
    return new Promise<void>(async (resolve) => {
      await SecureStoragePlugin.remove({ key });
      resolve();
    });
  }

  setItem(key: string, value: string) {
    return new Promise<void>(async (resolve) => {
      await SecureStoragePlugin.set({
        key,
        value: encodeURIComponent(value),
      });
      resolve();
    });
  }
}

const secureStorage = new SecureStorage();

export default secureStorage;

export const parseJSON = <T>(value: string | null = null): T | undefined => {
  try {
    if (!value) {
      return undefined;
    } else {
      return JSON.parse(value);
    }
  } catch {
    return undefined;
  }
};
