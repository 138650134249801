import { FC } from 'react';

import { Empty } from 'components/empty';

import s from './surveysEmpty.module.scss';

interface SurveysEmptyProps {
  text: string;
}

const SurveysEmpty: FC<SurveysEmptyProps> = (props) => {
  return (
    <div className={s.container}>
      <Empty title="survey_not_found" description={props.text} />
    </div>
  );
};

export default SurveysEmpty;
