import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import loupeImg from 'assets/icons/noData.svg';

import s from './empty.module.scss';

interface EmptyProps {
  title: string;
  description: string;
}

/**
 * Компонент для отображения пустых опросов/уведомлений и т.д.
 */

export default function Empty(props: EmptyProps) {
  const { title, description } = props;
  return (
    <div className={s.surveysContainer}>
      <img src={loupeImg} alt="" />
      <div className={s.text}>
        <Typography.Title level={4}>{t(title)}</Typography.Title>
        <Typography.Text size="16" type="secondary">
          {t(description)}
        </Typography.Text>
      </div>
    </div>
  );
}
