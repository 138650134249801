import { FC } from 'react';

import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';
import useFirebase from 'utils/useFirebase.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';

import s from './UnavailableSection.module.scss';

const UnavailableSection: FC = () => {
  const [, setSelectedEmployee] = useFullSelectedEmployee();
  const headerChildren = (
    <>
      <Header.Logo />
      <Header.Notification />
    </>
  );

  const auth = useAuth();

  const { unregister } = useFirebase();

  const onLogout = async () => {
    unregister();
    await auth.signoutSilent();
    setSelectedEmployee('');
  };

  return (
    <WithNavigation headerChildren={headerChildren}>
      <WithBackground>
        <Flex vertical justify="space-between" className={s.content}>
          <Card className={s.wrapper}>
            <Flex gap="16" vertical align="center">
              <Icon name="accountClosed" className={s.icon} />
              <Flex gap="4" vertical align="center">
                <Typography.Text size="18" weight="500">
                  {t('common_section_unavailable')}
                </Typography.Text>
                <Typography.Text size="16" type="secondary">
                  {t('common_section_unavailable_description')}
                </Typography.Text>
              </Flex>
            </Flex>
          </Card>
          <button onClick={onLogout} className={s.logout}>
            <Icon name="logout" />
            <Typography.Text size="14" weight="500">
              {t('home_finish_session')}
            </Typography.Text>
          </button>
        </Flex>
      </WithBackground>
    </WithNavigation>
  );
};

export default UnavailableSection;
