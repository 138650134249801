import { useEffect, useState } from 'react';

import { Progress as AProgress } from 'antd';

import { Typography } from 'gazprom-ui-lib';

import s from './progress.module.scss';

interface ProgressProps {
  current: number;
  all: number;
}

const Progress = (props: ProgressProps) => {
  const [percentage, setPercentage] = useState(0);
  const { current, all } = props;

  useEffect(() => {
    setPercentage(Math.round((current / all) * 100));
  }, [current, all]);

  return (
    <div className={s.container}>
      <AProgress percent={percentage} showInfo={false} />
      <Typography.Text size="12" type="secondary">
        Ответов: {current} из {all} / {isNaN(percentage) ? 0 : percentage}%
      </Typography.Text>
    </div>
  );
};

export default Progress;
