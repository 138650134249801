import { FC, ReactNode } from 'react';

import cn from 'classnames';

import { Typography } from 'gazprom-ui-lib';

import s from './HeaderLabel.module.scss';

interface HeaderLabelProps {
  label: ReactNode;
  className?: string;
}

const HeaderLabel: FC<HeaderLabelProps> = (props) => {
  const { label, className } = props;

  return (
    <Typography.Title level={5} className={cn(s.wrapper, className)}>
      {label}
    </Typography.Title>
  );
};

export default HeaderLabel;
