import { createSlice } from '@reduxjs/toolkit';

export const SLICE_NAME = 'downloadErrorModalSlice';

export type DownloadErrorModalSliceState = {
  isModalOpen: boolean;
};

const initialState: DownloadErrorModalSliceState = {
  isModalOpen: false,
};

export const downloadErrorModalSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    handleOpenDownloadErrorModal(state, action) {
      state.isModalOpen = action.payload;
    },
  },
});

export const { handleOpenDownloadErrorModal } = downloadErrorModalSlice.actions;

export default downloadErrorModalSlice.reducer;
