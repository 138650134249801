import { Navigate, RouteObject } from 'react-router-dom';
import { loginRoutes } from 'routes/login/config';
import { moreRoutes } from 'routes/more/config';
import useSecureStorage from 'utils/useSecureStorage.hook';

import UnavailableSection from 'containers/unavailable-section';

import Error from 'pages/error';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import { bonusesRoutes } from './bonuses/config';
import { documentsRoutes, excludedRoutesForPreUser } from './documents/config';
import { DOCUMENTS_ROUTE } from './documents/list';
import { guideRoutes } from './guide/config';
import { homeRoutes } from './home/config';
import { HOME_ROUTE, HOME_SWIPER_ROUTE } from './home/list';
import { LOGIN_ROUTE } from './login/list';
import { notificationsRoutes } from './notifications/config';
import { profileRoutes } from './profile/config';
import { regularArrangementRoutes } from './regular-arrangement/config';
import { salaryRoutes } from './salary/config';
import { scheduleRoutes } from './schedule/config';

export type RouteConfig = RouteObject & {
  children?: RouteConfig[];
};

export const UNKNOWN_ROUTE = '*';
export const HOME_NONE_AUTH_ROUTE = LOGIN_ROUTE;
export const ERROR_NONE_AUTH_ROUTE = '/error';

const UnknownRouteElement = () => {
  const [isHomeSwiperWasDisplayed] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_HOME_SWIPER,
    false,
  );

  if (isHomeSwiperWasDisplayed) {
    return <Navigate replace to={HOME_ROUTE} />;
  }

  return <Navigate replace to={HOME_SWIPER_ROUTE} />;
};

export const initRoutes = (isAuth: boolean, isPreUser: boolean) => {
  if (isAuth) {
    if (isPreUser) {
      const preUserDocumentsRoute = documentsRoutes.filter(
        (route) => !excludedRoutesForPreUser.includes(route.path!),
      );

      return [
        ...preUserDocumentsRoute,
        { path: '/', element: <Navigate replace to={DOCUMENTS_ROUTE} /> },
        { path: ERROR_NONE_AUTH_ROUTE, element: <Error /> },
        { path: UNKNOWN_ROUTE, element: <UnavailableSection /> },
      ];
    }

    return [
      ...bonusesRoutes,
      ...documentsRoutes,
      ...guideRoutes,
      ...homeRoutes,
      ...profileRoutes,
      ...regularArrangementRoutes,
      ...salaryRoutes,
      ...scheduleRoutes,
      ...notificationsRoutes,
      ...moreRoutes,
      { path: ERROR_NONE_AUTH_ROUTE, element: <Error /> },
      { path: UNKNOWN_ROUTE, element: <UnknownRouteElement /> },
    ];
  }

  return [
    { path: UNKNOWN_ROUTE, element: <Navigate replace to={HOME_NONE_AUTH_ROUTE} /> },
    { path: ERROR_NONE_AUTH_ROUTE, element: <Error /> },
    ...loginRoutes,
  ];
};
