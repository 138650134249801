import { lazy } from 'react';

import { RouteConfig } from '../routes';
import { BONUSES_ROUTE } from './list';

const Bonuses = lazy(() => import('pages/bonuses'));

export const bonusesRoutes: RouteConfig[] = [
  {
    path: BONUSES_ROUTE,
    element: <Bonuses />,
  },
];
