import { lazy } from 'react';

import { RouteConfig } from '../routes';
import { SALARY_ROUTE } from './list';

const Salary = lazy(() => import('pages/salary'));

export const salaryRoutes: RouteConfig[] = [
  {
    path: SALARY_ROUTE,
    element: <Salary />,
  },
];
