import { lazy } from 'react';

import { RouteConfig } from '../routes';
import { REGULAR_ARRANGEMENT_ROUTE } from './list';

const RegularArrangement = lazy(() => import('pages/regular-arrangement'));

export const regularArrangementRoutes: RouteConfig[] = [
  {
    path: REGULAR_ARRANGEMENT_ROUTE,
    element: <RegularArrangement />,
  },
];
