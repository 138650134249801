import { AuditPropsType, AuditResponseType } from 'services/audit/auditApiService.types';
import initialApiService from 'services/initialApiService';

import './auditApiService.types';

import { createAuditUrl } from './utils';

const auditApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    audit: builder.mutation<AuditResponseType, AuditPropsType>({
      query: (body) => ({
        url: createAuditUrl(''),
        body,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useAuditMutation } = auditApiService;
