import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import { Icon } from 'gazprom-ui-lib';

import Card from 'containers/card/index';

import s from './CardInfo.module.scss';

interface CardInfoProps extends PropsWithChildren {
  className?: string;
  type?: 'info' | 'error';
}

const CardInfo: FC<CardInfoProps> = (props) => {
  const { children, className, type = 'info' } = props;

  return (
    <Card className={cn(s.wrapper, s[type], className)}>
      <Icon name="info" />
      {children}
    </Card>
  );
};

export default CardInfo;
