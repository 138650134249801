import InternalCard from './card';
import CardButton from './card-button';
import CardDefault from './card-default';
import CardDot from './card-dot';
import CardFixed from './card-fixed';
import CardInfo from './card-info';
import CardNavigate from './card-navigate';

type CompoundedComponents = typeof InternalCard & {
  Default: typeof CardDefault;
  Dot: typeof CardDot;
  Button: typeof CardButton;
  Navigate: typeof CardNavigate;
  Info: typeof CardInfo;
  Fixed: typeof CardFixed;
};

const Card = InternalCard as CompoundedComponents;

Card.Default = CardDefault;
Card.Dot = CardDot;
Card.Button = CardButton;
Card.Navigate = CardNavigate;
Card.Info = CardInfo;
Card.Fixed = CardFixed;

export default Card;
