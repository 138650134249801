import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { compareVersions } from 'compare-versions';

import APP_VERSION from 'constants/appVersions';

const checkIsActualVersion = async () => {
  const platform = Capacitor.getPlatform();

  if (['android', 'ios'].includes(platform)) {
    const info = await CapacitorApp.getInfo();
    let actualVersion;
    if (platform === 'android') {
      actualVersion = APP_VERSION.ANDROID;
    } else {
      actualVersion = APP_VERSION.IOS;
    }

    const compare = compareVersions(info.version, actualVersion);
    return compare >= 0;
  } else {
    return true;
  }
};

export default checkIsActualVersion;
