import { useNavigate } from 'react-router-dom';
import { LOGIN_ACTIONS_ROUTE } from 'routes/login/list';
import { Trans, t } from 'tools/i18n';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import { Button, Checkbox, Form, Typography } from 'gazprom-ui-lib';

import LoginWrapper from '../login-wrapper';
import s from './LoginTermsAndAgreements.module.scss';

enum FORM_ENUM {
  TERMS,
  PERSONAL_DATA,
}

const LoginTermsAndAgreements = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const terms = Form.useWatch(FORM_ENUM.TERMS, form);
  const personalData = Form.useWatch(FORM_ENUM.PERSONAL_DATA, form);

  const isContinueButtonDisabled = !terms || !personalData;

  const onFinish = () => {
    navigate(LOGIN_ACTIONS_ROUTE);
  };

  const { handleOpenFile, loadingKey } = useOpenFileByUrl();

  return (
    <LoginWrapper>
      <Form form={form} className={s.form} onFinish={onFinish}>
        <Form.Item valuePropName="checked" name={FORM_ENUM.TERMS}>
          <Typography.Title level={5} strong={false}>
            <Checkbox className={s.checkboxTrans}>
              <Trans i18nKey="access_accept_terms">
                <Typography.Text strong={false}></Typography.Text>
                <a
                  href="#"
                  onClick={() => {
                    if (loadingKey) {
                      return;
                    }
                    handleOpenFile({
                      url: `${process.env.https://api-test.dev.tockl.ru/api/v1/}files/agreement.pdf`,
                      fileKey: 'agreement',
                    });
                  }}></a>
              </Trans>
            </Checkbox>
          </Typography.Title>
        </Form.Item>
        <Form.Item valuePropName="checked" name={FORM_ENUM.PERSONAL_DATA}>
          <Typography.Title level={5} strong={false}>
            <Checkbox className={s.checkboxTrans}>
              <Trans i18nKey="access_accept_personal_data">
                <Typography.Text strong={false}></Typography.Text>
                <a
                  href="#"
                  onClick={() => {
                    if (loadingKey) {
                      return;
                    }
                    handleOpenFile({
                      url: `${process.env.https://api-test.dev.tockl.ru/api/v1/}files/personal_data.pdf`,
                      fileKey: 'personal_data',
                    });
                  }}></a>
              </Trans>
            </Checkbox>
          </Typography.Title>
        </Form.Item>
        <Button
          htmlType="submit"
          rightIcon="arrowRight"
          size="large"
          fullWidth
          type="primary"
          disabled={isContinueButtonDisabled}>
          {t('common_continue')}
        </Button>
      </Form>
    </LoginWrapper>
  );
};

export default LoginTermsAndAgreements;
