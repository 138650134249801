import { lazy } from 'react';

import { RouteConfig } from '../routes';
import { NOTIFICATIONS_ROUTE, NOTIFICATION_ROUTE } from './list';

const Notifications = lazy(() => import('pages/notifications'));
const NotificationsSingle = lazy(() => import('pages/notifications/notifications-single'));

export const notificationsRoutes: RouteConfig[] = [
  {
    path: NOTIFICATIONS_ROUTE,
    element: <Notifications />,
  },
  {
    path: NOTIFICATION_ROUTE,
    element: <NotificationsSingle />,
  },
];
