import { FC, PropsWithChildren } from 'react';

import { Style } from '@capacitor/status-bar';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import WithStatusBar from 'containers/wrappers/with-status-bar';

import { ReactComponent as Line1 } from 'assets/icons/line1.svg';
import { ReactComponent as LogoFullName } from 'assets/logo/logo-full-name.svg';
import { ReactComponent as LogoSquare } from 'assets/logo/logo-short-name-square-blue.svg';

import s from './LoginWrapper.module.scss';

const LoginWrapper: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <WithStatusBar style={Style.Dark}>
      <div className={s.wrapper}>
        <div>
          <LogoFullName className={s.gsp5} />
          <div className={s.logoWithLine}>
            <Line1 />
            <div>
              <LogoSquare />
            </div>
          </div>
          <Typography.Title level={3}>{t('common_welcome')}</Typography.Title>
        </div>
        {children}
      </div>
    </WithStatusBar>
  );
};

export default LoginWrapper;
