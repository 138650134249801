import { prepareQueryParams } from 'gazprom-common-lib';

import initialApiService from 'services/initialApiService';

import {
  ConfirmCertificatePropsType,
  ConfirmCertificateResponseType,
  ConfirmCertificateSmsPropsType,
  ConfirmCertificateSmsResponseType,
  ConfirmPersonalDataPropsType,
  ConfirmPersonalDataResponseType,
  CreateCertificatePropsType,
  CreateCertificateResponseType,
  DeclineCertificatePropsType,
  DeclineCertificateResponseType,
  GetCertificatesPropsType,
  GetCertificatesResponseType,
  GetCurrentCertificatePropsType,
  GetCurrentCertificateResponseType,
  GetCurrentProcessingCertificatePersonDataPropsType,
  GetCurrentProcessingCertificatePersonDataResponseType,
  GetEmployeesResponseType,
  GetEventByIdPropsType,
  GetEventByIdResponseType,
  GetEventsPropsType,
  GetEventsResponseType,
  GetHeadDivisionLeadersPropsType,
  GetHeadDivisionLeadersResponseType,
  GetHeroConfigResponseType,
  GetInsurancePropsType,
  GetInsuranceResponseType,
  GetIsPreUserPropsType,
  GetIsPreUserResponseType,
  GetLeaderSubdivisionsPropsType,
  GetLeaderSubdivisionsResponseType,
  GetLeadersPropsType,
  GetLeadersResponseType,
  GetMeParticipantsPropsType,
  GetMeParticipantsResponseType,
  GetPersonalDataPropsType,
  GetPersonalDataResponseType,
  GetSchedulePropsType,
  GetScheduleResponseType,
  GetSuperiorsPropsType,
  GetSuperiorsResponseType,
  GetVacationPropsType,
  GetVacationResponseType,
  GetVacationSchedulePropsType,
  GetVacationScheduleResponseType,
  LeaveCampPropsType,
  LeaveCampResponseType,
  RequestPersonalDataPropsType,
  RequestPersonalDataResponseType,
  SignConfirmPropsType,
  SignConfirmResponseType,
  SignPrivatePropsType,
  SignPrivateResponseType,
  SignPropsType,
  SignResponseType,
} from './meApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createMeUrl } from './utils';

const meApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSchedule: builder.query<GetScheduleResponseType, GetSchedulePropsType>({
      query: (params) => ({
        url: createMeUrl(`/schedule${prepareQueryParams(params)}`),
      }),
    }),
    getEmployees: builder.query<GetEmployeesResponseType, void>({
      query: () => ({
        url: createMeUrl(`/employees`),
      }),
      providesTags: [SERVICE_TAGS.GET_EMPLOYEES],
    }),
    getLeaders: builder.query<GetLeadersResponseType, GetLeadersPropsType>({
      query: (params) => ({
        url: createMeUrl(`/my-leaders${prepareQueryParams(params)}`),
      }),
    }),
    getSuperiors: builder.query<GetSuperiorsResponseType, GetSuperiorsPropsType>({
      query: (params) => ({
        url: createMeUrl(`/superior-divisions${prepareQueryParams(params)}`),
      }),
    }),
    getLeaderSubdivisions: builder.query<
      GetLeaderSubdivisionsResponseType,
      GetLeaderSubdivisionsPropsType
    >({
      query: (params) => ({
        url: createMeUrl(`/leader-subdivisions${prepareQueryParams(params)}`),
      }),
    }),
    getHeroConfig: builder.query<GetHeroConfigResponseType, void>({
      query: () => ({
        url: createMeUrl(`/is-hero`),
      }),
    }),
    getEvents: builder.query<GetEventsResponseType, GetEventsPropsType>({
      query: (params) => ({
        url: createMeUrl(`/meet${prepareQueryParams(params)}`),
      }),
    }),
    getEventById: builder.query<GetEventByIdResponseType, GetEventByIdPropsType>({
      query: ({ id, ...other }) => ({
        url: createMeUrl(`/meet/${id}${prepareQueryParams(other)}`),
      }),
    }),
    getCertificates: builder.query<GetCertificatesResponseType, GetCertificatesPropsType>({
      query: () => ({
        url: createMeUrl(`/kcr`),
      }),
      providesTags: [SERVICE_TAGS.GET_CERTIFICATES],
    }),
    getIsPreUser: builder.query<GetIsPreUserResponseType, GetIsPreUserPropsType>({
      query: () => ({
        url: createMeUrl(`/is-pre`),
      }),
      providesTags: [],
    }),
    getCurrentProcessingCertificatePersonData: builder.query<
      GetCurrentProcessingCertificatePersonDataResponseType,
      GetCurrentProcessingCertificatePersonDataPropsType
    >({
      query: (id) => ({
        url: createMeUrl(`/kcr/issue/${id}/person`),
      }),
    }),
    createCertificate: builder.mutation<CreateCertificateResponseType, CreateCertificatePropsType>({
      query: () => ({
        url: createMeUrl(`/kcr/issue`),
        method: 'POST',
      }),
    }),
    confirmCertificate: builder.mutation<
      ConfirmCertificateResponseType,
      ConfirmCertificatePropsType
    >({
      query: ({ id, type }) => ({
        url: createMeUrl(`/kcr/issue/${id}/confirm?confirmationType=${type}`),
        method: 'POST',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_CERTIFICATES],
    }),
    confirmCertificateSms: builder.mutation<
      ConfirmCertificateSmsResponseType,
      ConfirmCertificateSmsPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createMeUrl(`/kcr/issue/${id}/sms`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_CERTIFICATES],
    }),
    declineCertificate: builder.mutation<
      DeclineCertificateResponseType,
      DeclineCertificatePropsType
    >({
      query: (id) => ({
        url: createMeUrl(`/kcr/issue/${id}/decline`),
        method: 'POST',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_CERTIFICATES],
    }),
    getCurrentCertificate: builder.query<
      GetCurrentCertificateResponseType,
      GetCurrentCertificatePropsType
    >({
      query: () => ({
        url: createMeUrl(`/kcr/state`),
      }),
      providesTags: [SERVICE_TAGS.GET_CERTIFICATES],
    }),
    sign: builder.mutation<SignResponseType, SignPropsType>({
      query: (params) => ({
        url: createMeUrl(`/sign`),
        method: 'POST',
        body: params,
      }),
    }),
    signPrivate: builder.mutation<SignPrivateResponseType, SignPrivatePropsType>({
      query: ({ myEmployeeId, ...body }) => ({
        url: createMeUrl(`/sign/private?myEmployeeId=${myEmployeeId}`),
        method: 'POST',
        body,
      }),
    }),
    signConfirm: builder.mutation<SignConfirmResponseType, SignConfirmPropsType>({
      query: (body) => ({
        url: createMeUrl(`/sign/confirm`),
        method: 'POST',
        body,
      }),
    }),
    leaveCamp: builder.mutation<LeaveCampResponseType, LeaveCampPropsType>({
      query: (id) => ({
        url: createMeUrl(`/settlement/deactivate?myEmployeeId=${id}`),
        method: 'POST',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_EMPLOYEES, SERVICE_TAGS.GET_GUIDE_HISTORY],
    }),
    getPersonalData: builder.query<GetPersonalDataResponseType, GetPersonalDataPropsType>({
      query: () => ({
        url: createMeUrl('/personal'),
        method: 'GET',
      }),
      providesTags: [SERVICE_TAGS.GET_EMPLOYEE_PERSONAL_DATA],
    }),
    requestPersonalData: builder.mutation<
      RequestPersonalDataResponseType,
      RequestPersonalDataPropsType
    >({
      query: () => ({
        url: createMeUrl('/personal/request'),
        method: 'POST',
      }),
    }),
    confirmPersonalData: builder.mutation<
      ConfirmPersonalDataResponseType,
      ConfirmPersonalDataPropsType
    >({
      query: ({ smsCode }) => ({
        url: createMeUrl('/personal/request/confirm'),
        method: 'POST',
        body: { smsCode },
      }),
      invalidatesTags: [SERVICE_TAGS.GET_EMPLOYEE_PERSONAL_DATA],
    }),
    getVacationSchedule: builder.query<
      GetVacationScheduleResponseType,
      GetVacationSchedulePropsType
    >({
      query: (params) => ({
        url: createMeUrl(`/vacation${prepareQueryParams(params)}`),
        method: 'GET',
      }),
    }),
    getVacation: builder.query<GetVacationResponseType, GetVacationPropsType>({
      query: (params) => ({
        url: `/vacation${prepareQueryParams(params)}`,
        method: 'GET',
      }),
    }),
    getInsurance: builder.query<GetInsuranceResponseType, GetInsurancePropsType>({
      query: (params) => ({
        url: createMeUrl(`/insurance${prepareQueryParams(params)}`),
      }),
    }),
    getMeParticipants: builder.query<GetMeParticipantsResponseType, GetMeParticipantsPropsType>({
      query: (params) => ({
        url: createMeUrl(`/employer-participants${prepareQueryParams(params)}`),
      }),
    }),
    getHeadDivisionLeaders: builder.query<
      GetHeadDivisionLeadersResponseType,
      GetHeadDivisionLeadersPropsType
    >({
      query: (params) => ({
        url: createMeUrl(`/my-leaders/head-divisions${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const getEmployeesSelector = meApiService.endpoints.getEmployees.select();
export const getHeroConfigSelector = meApiService.endpoints.getHeroConfig.select();

export const {
  useGetVacationScheduleQuery,
  useLazyGetVacationQuery,
  useGetVacationQuery,
  useConfirmCertificateSmsMutation,
  useLeaveCampMutation,
  useConfirmCertificateMutation,
  useDeclineCertificateMutation,
  useLazyGetCurrentProcessingCertificatePersonDataQuery,
  useGetCurrentCertificateQuery,
  useGetCertificatesQuery,
  useGetEmployeesQuery,
  useGetHeroConfigQuery,
  useLazyGetEventByIdQuery,
  useLazyGetEventsQuery,
  useLazyGetEmployeesQuery,
  useGetLeadersQuery,
  useLazyGetSuperiorsQuery,
  useGetLeaderSubdivisionsQuery,
  useLazyGetHeroConfigQuery,
  useGetScheduleQuery,
  useCreateCertificateMutation,
  useSignMutation,
  useSignPrivateMutation,
  useSignConfirmMutation,
  useLazyGetPersonalDataQuery,
  useGetPersonalDataQuery,
  useRequestPersonalDataMutation,
  useConfirmPersonalDataMutation,
  useGetInsuranceQuery,
  useLazyGetIsPreUserQuery,
  useLazyGetMeParticipantsQuery,
  useGetHeadDivisionLeadersQuery,
} = meApiService;
