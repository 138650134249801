import { useEffect } from 'react';

import { PRE_USER_ROLE, UserProfileType } from 'gazprom-common-lib';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { HOME_SELECT_COMPANY } from 'routes/home/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGetCertificatesQuery,
  useGetEmployeesQuery,
  useGetHeroConfigQuery,
} from 'services/me/meApiService';

const useInitialAuthRequestsHook = () => {
  const auth = useAuth();
  const [selectedEmployee, setSelectedEmployee] = useFullSelectedEmployee();
  const navigate = useNavigate();
  const { isLoading: getCertificatesIsLoading } = useGetCertificatesQuery(undefined, {
    skip: !auth?.isAuthenticated,
  });
  const { isLoading: getEmployeesIsLoading, data: employeesData } = useGetEmployeesQuery(
    undefined,
    {
      skip: !auth?.isAuthenticated,
    },
  );
  const { isLoading: getHeroConfigIsLoading } = useGetHeroConfigQuery(undefined, {
    skip: !auth?.isAuthenticated,
  });

  const userProfile = auth?.user?.profile as UserProfileType;
  const isPreUser = !!userProfile?.roles?.includes(PRE_USER_ROLE);

  useEffect(() => {
    if (employeesData) {
      if (selectedEmployee) {
        const currentEmployeeDoesNotExist = !employeesData.find(
          (e) => e.id === selectedEmployee.id,
        );
        if (currentEmployeeDoesNotExist) {
          setSelectedEmployee(employeesData[0]?.id);
        }
      } else {
        if (isPreUser) {
          setSelectedEmployee(employeesData[0]?.id);
        } else {
          navigate(HOME_SELECT_COMPANY);
        }
      }
    }
  }, [employeesData, isPreUser, navigate, selectedEmployee, setSelectedEmployee]);

  const isLoading = getEmployeesIsLoading || getHeroConfigIsLoading || getCertificatesIsLoading;

  return {
    isLoading,
  };
};

export default useInitialAuthRequestsHook;
