import { createSlice } from '@reduxjs/toolkit';
import { EmployeeType } from 'gazprom-common-lib';

export const SLICE_NAME = 'employeeSlice';
export type ActiveEmployeeType = EmployeeType | null;

export type EmployeeSliceState = {
  activeCompany: ActiveEmployeeType;
};

const initialState: EmployeeSliceState = {
  activeCompany: null,
};

export const employeeSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setActiveEmployee(state, action) {
      state.activeCompany = action.payload;
    },
  },
});

export const { setActiveEmployee } = employeeSlice.actions;

export default employeeSlice.reducer;
