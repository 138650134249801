import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetSurveyAnswersResponse } from 'services/survey/surveyApiService.types';

interface SurveySliceState {
  isCompleted: boolean;
  answers: GetSurveyAnswersResponse | null;
}

const initialState: SurveySliceState = {
  isCompleted: false,
  answers: null,
};

export const surveySlice = createSlice({
  name: 'surveySlice',
  initialState,
  reducers: {
    setSurveysValues(state, action: PayloadAction<SurveySliceState>) {
      state.isCompleted = action.payload.isCompleted;
      state.answers = action.payload.answers;
    },
  },
});

export const { setSurveysValues } = surveySlice.actions;

export default surveySlice.reducer;
