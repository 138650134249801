import { FC, PropsWithChildren, forwardRef } from 'react';

import cn from 'classnames';

import s from './WithBackground.module.scss';

interface WithBackgroundProps extends PropsWithChildren {
  className?: string;
  id?: string;
}

const WithBackground: FC<WithBackgroundProps> = forwardRef<HTMLDivElement, WithBackgroundProps>(
  (props, ref) => {
    const { className, id, children } = props;

    return (
      <div ref={ref} id={id} className={cn(s.wrapper, className)}>
        {children}
      </div>
    );
  },
);

export default WithBackground;
