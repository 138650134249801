import { FC, ReactElement, useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

interface WithStatusBarProps {
  children: ReactElement;
  style?: Style;
}

const WithStatusBar: FC<WithStatusBarProps> = (props) => {
  const { children, style = Style.Light } = props;

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      StatusBar.setStyle({ style });
    }
  }, [style]);

  return children;
};

export default WithStatusBar;
