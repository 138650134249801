import { FC, ReactElement, useEffect, useRef } from 'react';

import { App } from '@capacitor/app';

interface WithIdleProps {
  children: ReactElement;
  handleIdle: () => void;
}

const WithIdle: FC<WithIdleProps> = (props) => {
  const { children, handleIdle } = props;

  const isActiveRef = useRef<boolean>(false);
  const blurStartTime = useRef<number | null>(null);

  useEffect(() => {
    const onFocus = () => {
      if (blurStartTime.current) {
        if (Date.now() - IDLE_TIME > blurStartTime.current) {
          setTimeout(() => handleIdle(), 100);
        }
      }
    };
    const onBlur = () => {
      blurStartTime.current = Date.now();
    };

    const onAnyAction = ({ isActive }: { isActive: boolean }) => {
      if (isActive) {
        onFocus();
      } else {
        onBlur();
      }
    };

    if (!isActiveRef.current) {
      App.addListener('appStateChange', onAnyAction);
      isActiveRef.current = true;
    }
  }, [handleIdle]);

  return children;
};

const IDLE_TIME = 5 * 60 * 1000;

export default WithIdle;
