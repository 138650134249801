export const PROFILE_ROUTE = '/profile';
export const PROFILE_AUTH_ROUTE = `${PROFILE_ROUTE}/auth`;
export const PROFILE_AUTH_CHANGE_PIN_ROUTE = `${PROFILE_AUTH_ROUTE}/change-pin`;
export const PROFILE_PAYSLIPS_ROUTE = `${PROFILE_ROUTE}/payslips`;
export const PROFILE_PERSONAL_DATA_ROUTE = `${PROFILE_ROUTE}/personal-data`;
export const PROFILE_COMPANY_ROUTE = `${PROFILE_ROUTE}/company`;
export const PROFILE_SCHEDULE_ROUTE = `${PROFILE_ROUTE}/schedule`;
export const PROFILE_MY_SALARY_ROUTE = `${PROFILE_ROUTE}/my-salary`;
export const PROFILE_MY_SALARY_PAYSLIP_ROUTE = `${PROFILE_ROUTE}/my-salary/payslip`;

export const PROFILE_VACATION_ROUTE = `${PROFILE_ROUTE}/vacation`;

export const PROFILE_VACATION_EMPLOYEES_ROUTE = `${PROFILE_VACATION_ROUTE}/employees`;

export const PROFILE_VACATION_AGREED_ROUTE = `${PROFILE_VACATION_EMPLOYEES_ROUTE}/agreed`;

export const PROFILE_VACATION_AGREED_WITH_ID_ROUTE = `${PROFILE_VACATION_AGREED_ROUTE}/:divisionId`;

export const getProfileVacationAgreedRoute = (id: string) =>
  `${PROFILE_VACATION_AGREED_ROUTE}/${id}`;

export const PROFILE_EMPLOYEE_VACATION_ROUTE = `${PROFILE_VACATION_AGREED_WITH_ID_ROUTE}/:employeeId/:positionId`;

export const getEmployeeVacationRoute = (divisionId: string, id: string, positionId: string) =>
  `${PROFILE_VACATION_AGREED_ROUTE}/${divisionId}/${id}/${positionId}`;
