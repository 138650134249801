import { createSlice } from '@reduxjs/toolkit';
import { PreparedKedoTask } from 'gazprom-common-lib';

export const SLICE_NAME = 'kedoSlice';

export type KedoSliceState = {
  activeKedoFormTask: null | PreparedKedoTask;
  activeKedoTask: null | any;
};

const initialState: KedoSliceState = {
  activeKedoFormTask: null,
  activeKedoTask: null,
};

export const kedoSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setActiveKedoTask(state, action) {
      state.activeKedoTask = action.payload;
    },
    setActiveKedoFormTask(state, action) {
      state.activeKedoFormTask = action.payload;
    },
  },
});

export const { setActiveKedoTask, setActiveKedoFormTask } = kedoSlice.actions;

export default kedoSlice.reducer;
