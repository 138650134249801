import { PropsWithChildren } from 'react';

import { useDispatch } from 'react-redux';
import { t } from 'tools/i18n';

import { downloadErrorModalStateSelector } from 'selectors/downloadErrorModalSlice.selector';
import { handleOpenDownloadErrorModal } from 'slices/downloadErrorModalSlice';
import { useAppSelector } from 'store';

import { Button, ModalDefault } from 'gazprom-ui-lib';

const WithDownloadErrorModal = (props: PropsWithChildren) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { isModalOpen } = useAppSelector(downloadErrorModalStateSelector);

  const handleClose = () => dispatch(handleOpenDownloadErrorModal(false));

  return (
    <>
      {children}
      <ModalDefault open={isModalOpen} onCancel={handleClose} closable>
        <ModalDefault.Icon name="error" type="danger" />
        <ModalDefault.Title>{t('documents_status_error_label')}</ModalDefault.Title>
        <ModalDefault.Description>{t('common_download_file_error')}</ModalDefault.Description>
        <ModalDefault.Actions>
          <Button type="secondary" onClick={handleClose} fullWidth size="large">
            {t('common_close')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default WithDownloadErrorModal;
