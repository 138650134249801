import { FC, PropsWithChildren, ReactNode } from 'react';

import cn from 'classnames';

import Header from 'containers/header';
import Navbar, { RouteType } from 'containers/navbar';

import s from './WithNavigation.module.scss';

export interface WithNavigationProps extends PropsWithChildren {
  navbarConfig?: RouteType[];
  headerChildren?: ReactNode;
  showNavbar?: boolean;
  contentClassName?: string;
}

const WithNavigation: FC<WithNavigationProps> = (props) => {
  const { contentClassName, children, navbarConfig, headerChildren, showNavbar = true } = props;

  return (
    <div className={s.wrapper}>
      <Header className={s.header} children={headerChildren} />
      <div className={cn(s.content, contentClassName, { [s.contentWithoutNavbar]: !showNavbar })}>
        {children}
      </div>
      {showNavbar && <Navbar className={s.navigation} navbarConfig={navbarConfig} />}
    </div>
  );
};

export default WithNavigation;
