import { FC, useState } from 'react';

import { NativeBiometric } from 'capacitor-native-biometric';
import { t } from 'tools/i18n';
import { useAudit } from 'utils/useAudit.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { Button, Typography } from 'gazprom-ui-lib';

import { ReactComponent as IconFaceId } from 'assets/icons/faceId.svg';
import { ReactComponent as IconFaceIdReady } from 'assets/icons/faceIdReady.svg';

import AUDIT_EVENTS from 'constants/auditEvents';
import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import s from './IdentifierAddFaceid.module.scss';

interface IdentifierAddFaceidProps {
  pass: string;
}

const IdentifierAddFaceid: FC<IdentifierAddFaceidProps> = (props) => {
  const { pass } = props;

  const [, setPassIsInstalled] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_INSTALLED_PASS,
    '',
  );
  const [, setFaceIdIsInstalled] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_IS_FACE_INSTALLED,
    false,
  );

  const { auditSuccess } = useAudit();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const handleConnect = () => {
    NativeBiometric.verifyIdentity({
      title: 'Face ID',
    }).then(() => {
      auditSuccess(AUDIT_EVENTS.SETUP.BIOMETRY);
      setIsConnected(true);
      setFaceIdIsInstalled(true);
    });
  };

  const handleSkip = () => {
    setPassIsInstalled(pass);
  };

  const handleSubmit = () => {
    setPassIsInstalled(pass);
  };

  let content = (
    <>
      <div className={s.textWrapper}>
        <IconFaceId className={s.faceIdConnect} />
        <Typography.Title level={3}>{t('identifier_connect_face_id')}</Typography.Title>
        <Typography.Text size="16" type="secondary" className={s.faceIdDescription}>
          {t('identifier_connect_face_id_description_header')}
        </Typography.Text>
        <Typography.Text size="16" type="secondary">
          {t('identifier_connect_face_id_description_body')}
        </Typography.Text>
      </div>
      <div className={s.actonWrapper}>
        <Button rightIcon="arrowRight" fullWidth onClick={handleConnect} size="large">
          {t('common_connect')}
        </Button>
        <Button type="primary" fullWidth onClick={handleSkip} ghost size="large">
          {t('common_skip')}
        </Button>
      </div>
    </>
  );

  if (isConnected) {
    content = (
      <>
        <div className={s.textWrapper}>
          <IconFaceIdReady className={s.faceIdConnected} />
          <Typography.Title level={3}>{t('identifier_connected_face_id')}</Typography.Title>
          <Typography.Text size="16" type="secondary" className={s.faceIdDescription}>
            {t('identifier_connected_face_id_description_header')}
          </Typography.Text>
          <Typography.Text size="16" type="secondary">
            {t('identifier_connected_face_id_description_body')}
          </Typography.Text>
        </div>
        <div className={s.actonWrapper}>
          <Button leftIcon="ok" fullWidth onClick={handleSubmit} size="large">
            {t('common_ready')}
          </Button>
        </div>
      </>
    );
  }

  return content;
};

export default IdentifierAddFaceid;
