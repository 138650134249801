import { FC } from 'react';

import cn from 'classnames';

import s from './UniversalLoader.module.scss';

interface UniversalLoaderProps {
  className?: string;
  size?: '20' | '24';
}

const UniversalLoader: FC<UniversalLoaderProps> = (props) => {
  const { className, size } = props;

  return <div className={cn(s.loader, className, s[`size${size}`])} />;
};

export default UniversalLoader;
