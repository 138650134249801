export const MORE_ROUTE = '/more';
export const MORE_EDUCATION_ROUTE = `${MORE_ROUTE}/education`;
export const MORE_EDUCATION_BDD_ROUTE = `${MORE_EDUCATION_ROUTE}/bdd`;
export const MORE_EDUCATION_WORK_SECURITY_ROUTE = `${MORE_EDUCATION_ROUTE}/work-security`;
export const MORE_EDUCATION_FIRE_SECURITY_ROUTE = `${MORE_EDUCATION_ROUTE}/fire-security`;
export const MORE_EDUCATION_MANUFACTURING_JOBS_ROUTE = `${MORE_EDUCATION_ROUTE}/manufacturing-jobs`;

export const MORE_DMS_ROUTE = `${MORE_ROUTE}/dms`;

export const MORE_FAQ_ROUTE = `${MORE_ROUTE}/faq`;

export const MORE_FAQ_CATEGORY_ROUTE = `${MORE_FAQ_ROUTE}/category`;
export const MORE_FAQ_CATEGORY_DETAILS_ROUTE = `${MORE_FAQ_CATEGORY_ROUTE}/:faqCategoryId`;
export const getFaqCategoryDetailsRoute = (id: string | number) =>
  `${MORE_FAQ_CATEGORY_ROUTE}/${id}`;

export const MORE_FAQ_QUESTION_ROUTE = `${MORE_FAQ_ROUTE}/question`;
export const MORE_FAQ_QUESTION_DETAILS_ROUTE = `${MORE_FAQ_QUESTION_ROUTE}/:faqQuestionId`;
export const getFaqQuestionDetailsRoute = (id: string | number) =>
  `${MORE_FAQ_QUESTION_ROUTE}/${id}`;

export const MORE_SUGGESTION_ROUTE = `${MORE_ROUTE}/suggestions`;
export const MORE_SUGGESTION_CHOOSE_CATEGORY_ROUTE = `${MORE_SUGGESTION_ROUTE}/choose`;
export const MORE_SUGGESTION_CREATE_ROUTE = `${MORE_SUGGESTION_ROUTE}/create/:categoryId`;
export const MORE_SUGGESTION_CORRECTION_ROUTE = `${MORE_SUGGESTION_CREATE_ROUTE}/:suggestionId`;
export const MORE_SUGGESTION_REQUEST_ROUTE = `${MORE_SUGGESTION_ROUTE}/:id`;
export const MORE_SUGGESTION_REQUEST_DETAILS_ROUTE = `${MORE_SUGGESTION_REQUEST_ROUTE}/details`;
export const MORE_SUGGESTION_DECISION_ROUTE = `${MORE_SUGGESTION_ROUTE}/decision/:id`;
export const MORE_SUGGESTION_DECISION_DETAILS_ROUTE = `${MORE_SUGGESTION_DECISION_ROUTE}/details/:decisionId`;
export const MORE_SUGGESTION_DECISION_PERFORMER_ROUTE = `${MORE_SUGGESTION_DECISION_ROUTE}/performer/:decisionId`;
export const MORE_SUGGESTION_DECISION_RESULT_ROUTE = `${MORE_SUGGESTION_DECISION_ROUTE}/result`;

export const getSuggestionRequestRoute = (id: string) => `${MORE_SUGGESTION_ROUTE}/${id}`;
export const getSuggestionRequestDetailsRoute = (id?: string) =>
  `${MORE_SUGGESTION_ROUTE}/${id}/details`;
export const getSuggestionCreateRoute = (categoryId: string) =>
  `${MORE_SUGGESTION_ROUTE}/create/${categoryId}`;
export const getSuggestionCorrectionRoute = (categoryId: string, suggestionId: string) =>
  `${MORE_SUGGESTION_ROUTE}/create/${categoryId}/${suggestionId}`;
export const getSuggestionDecisionRoute = (id?: string) =>
  `${MORE_SUGGESTION_ROUTE}/decision/${id}`;
export const getSuggestionDecisionDetailsRoute = (id?: string, decisionId?: string) =>
  `${getSuggestionDecisionRoute(id)}/details/${decisionId}`;
export const getSuggestionDecisionPerformerRoute = (id?: string, decisionId?: string) =>
  `${getSuggestionDecisionRoute(id)}/performer/${decisionId}`;
export const getSuggestionDecisionResultRoute = (id?: string) =>
  `${getSuggestionDecisionRoute(id)}/result`;

export const MORE_SURVEYS_ROUTE = `${MORE_ROUTE}/surveys`;
export const MORE_SURVEY_ROUTE = `${MORE_SURVEYS_ROUTE}/:anketologId/:revisionId/:anketaLinkId`;
export const getMoreSurveyRoute = (anketologId: string, revisionId: number, anketaLinkId: string) =>
  `${MORE_SURVEYS_ROUTE}/${anketologId}/${revisionId}/${anketaLinkId}`;

export const MORE_FEEDBACK_ROUTE = `${MORE_ROUTE}/feedbacks`;
export const MORE_FEEDBACK_CREATE_ROUTE = `${MORE_FEEDBACK_ROUTE}/create`;
export const MORE_FEEDBACK_DETAILS_ROUTE = `${MORE_FEEDBACK_ROUTE}/:id`;
export const getProfileFeedbackDetailsRoute = (id: string | number) =>
  `${MORE_FEEDBACK_ROUTE}/${id}`;

export const MORE_ABOUT_COMPANY_ROUTE = `${MORE_ROUTE}/about-company`;

export const MORE_COMMUNITIES_ROUTE = `${MORE_ROUTE}/communities`;
export const MORE_COMMUNITY_DETAILS_ROUTE = `${MORE_COMMUNITIES_ROUTE}/:communityId`;
