import { t } from 'tools/i18n';

import { Icon, IconProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from './firstTimeVisit.module.scss';

interface FirstTimeVisitProps {
  title: string;
  description: string;
  icon: IconProps;
  storageKey: string;
  backgroundIcon?: string;
}

/**
 * Компонент для отображения карточки с информацией при первичном посещении страницы.
 * При повторном посещении компонент отображаться не будет.
 */

export default function FirstTimeVisit(props: FirstTimeVisitProps) {
  const { title, description, icon, backgroundIcon = '--color-primary-6' } = props;

  return (
    <Card withPadding>
      <div className={s.first_time_container}>
        <div className={s.text}>
          <Typography.Title level={4}>{t(title)}</Typography.Title>
          <Typography.Text size="14" type="secondary">
            {t(description)}
          </Typography.Text>
        </div>
        <div className={s.first_time_icon} style={{ background: `var(${backgroundIcon})` }}>
          <Icon {...icon} />
        </div>
      </div>
    </Card>
  );
}
