import { lazy } from 'react';

import { RouteConfig } from '../routes';
import {
  HOME_NEWS_DETAILS_ROUTE,
  HOME_NEWS_ROUTE,
  HOME_NEWS_TAGS_ROUTE,
  HOME_PROMOCODES_DETAILS_ROUTE,
  HOME_PROMOCODES_ORDER_BY_POST,
  HOME_PROMOCODES_ORDER_PICKUP,
  HOME_PROMOCODES_ROUTE,
  HOME_ROUTE,
  HOME_SELECT_COMPANY,
  HOME_SWIPER_ROUTE,
} from './list';

const Home = lazy(() => import('pages/home'));
const HomeNews = lazy(() => import('pages/home/home-news'));
const HomeNewsTags = lazy(() => import('pages/home/home-news-tags'));
const HomeSelectCompany = lazy(() => import('pages/home/home-select-company'));
const HomeSwiper = lazy(() => import('pages/home/home-swiper'));
const HomePromocodes = lazy(() => import('pages/home/home-promocodes'));
const HomePromocodesOrder = lazy(() => import('pages/home/home-promocodes/home-promocodes-order'));
const HomePromocodesDetails = lazy(
  () => import('pages/home/home-promocodes/home-promocodes-details'),
);

export const homeRoutes: RouteConfig[] = [
  {
    path: HOME_ROUTE,
    element: <Home />,
  },
  { path: HOME_SWIPER_ROUTE, element: <HomeSwiper /> },
  { path: HOME_SELECT_COMPANY, element: <HomeSelectCompany /> },
  { path: HOME_NEWS_ROUTE, element: <HomeNews /> },
  { path: HOME_NEWS_DETAILS_ROUTE, element: <HomeNews /> },
  { path: HOME_NEWS_TAGS_ROUTE, element: <HomeNewsTags /> },
  { path: HOME_PROMOCODES_ROUTE, element: <HomePromocodes /> },
  { path: HOME_PROMOCODES_ORDER_BY_POST, element: <HomePromocodesOrder /> },
  { path: HOME_PROMOCODES_ORDER_PICKUP, element: <HomePromocodesOrder /> },
  { path: HOME_PROMOCODES_DETAILS_ROUTE, element: <HomePromocodesDetails /> },
];
