import { useCallback, useEffect } from 'react';

import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { useRegisterTokenMutation } from 'services/firebase/firebaseApiService';

import { APP_ID } from 'constants/appId';
import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import useSecureStorage from './useSecureStorage.hook';

const useFirebase = () => {
  const { isAuthenticated } = useAuth();
  const [pass] = useSecureStorage(SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_INSTALLED_PASS, '');
  const [registerToken] = useRegisterTokenMutation();
  const navigate = useNavigate();

  useEffect(() => {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (actionPerformed: ActionPerformed) => {
        const url = actionPerformed.notification?.data?.url;
        if (url) {
          if (url.includes(`${APP_ID}://`)) {
            navigate(url.replace(`${APP_ID}://`, '/'));
          } else {
            Browser.open({
              url,
            });
          }
        }
      },
    );
  }, [navigate]);

  const unregister = useCallback(async () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    await PushNotifications.unregister();
  }, []);

  const register = useCallback(async () => {
    if (!Capacitor.isNativePlatform() || !isAuthenticated || !pass) {
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.addListener('registration', (token) => {
      registerToken(token.value);
    });

    await PushNotifications.register();
  }, [isAuthenticated, pass, registerToken]);

  return {
    register,
    unregister,
  };
};

export default useFirebase;
