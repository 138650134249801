import { SLICE_NAME } from 'slices/secureStorageSlice';
import { RootState } from 'store';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

export const secureStorageStateSelector = (state: RootState) => state[SLICE_NAME];

export const storedValueSelector = (key: string) => (state: RootState) =>
  secureStorageStateSelector(state).data[key];

export const activeNewsTagsSelector = (state: RootState): string[] =>
  secureStorageStateSelector(state).data[SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_NEWS_TAGS];
export const activeNewsEventsTagsSelector = (state: RootState): string[] =>
  secureStorageStateSelector(state).data[SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_NEWS_EVENTS_TAGS];
