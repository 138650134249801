import initialApiService from 'services/initialApiService';

import { RegisterTokenPropsType, RegisterTokenResponseType } from './firebaseApiService.types';

import { createFirebaseUrl } from './utils';

const firebaseApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    registerToken: builder.mutation<RegisterTokenResponseType, RegisterTokenPropsType>({
      query: (token) => ({
        url: createFirebaseUrl(`/register`),
        method: 'POST',
        body: { token },
      }),
    }),
  }),
});

export const { useRegisterTokenMutation } = firebaseApiService;
