import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './CardFixed.module.scss';

interface CardFixedProps extends PropsWithChildren {
  className?: string;
}

const CardFixed: FC<CardFixedProps> = (props) => {
  const { children, className } = props;

  return <div className={cn(s.wrapper, className)}>{children}</div>;
};

export default CardFixed;
