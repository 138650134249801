import { FC } from 'react';

import cn from 'classnames';

import { Icon } from 'gazprom-ui-lib';

import s from './HeaderGoBackButton.module.scss';

interface HeaderGoBackProps {
  onClick: () => void;
  className?: string;
}

const HeaderGoBackButton: FC<HeaderGoBackProps> = (props) => {
  const { onClick, className } = props;

  return (
    <button onClick={onClick} className={cn(s.wrapper, className)} type="button">
      <Icon name="arrowLeft" />
    </button>
  );
};

export default HeaderGoBackButton;
