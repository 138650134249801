import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const SLICE_NAME = 'secureStorageSlice';

interface SecureStorageSliceState {
  data: Record<string, any>;
  isStorageLoaded: boolean;
}

const initialState: SecureStorageSliceState = {
  data: {},
  isStorageLoaded: false,
};

export const secureStorageSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    init(state, action: PayloadAction<SecureStorageSliceState['data']>) {
      state.isStorageLoaded = true;
      state.data = action.payload;
    },
    setValuesToReduxSecureStorageClone(
      state,
      action: PayloadAction<{ key: string; value: unknown }>,
    ) {
      state.data[action.payload.key] = action.payload.value;
    },
  },
});

export const { init, setValuesToReduxSecureStorageClone } = secureStorageSlice.actions;

export default secureStorageSlice.reducer;
