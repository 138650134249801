import { createSlice } from '@reduxjs/toolkit';

export const SLICE_NAME = 'guideSlice';
export type GuideSliceState = {
  QRCode: null | string;
  location: { x: string; y: string } | null;
};

const initialState: GuideSliceState = {
  QRCode: null,
  location: null,
};

export const guideSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setQRCode(state, { payload }) {
      state.QRCode = payload;
    },
    setLocation(state, { payload }) {
      state.location = payload;
    },
  },
});

export const { setQRCode, setLocation } = guideSlice.actions;

export default guideSlice.reducer;
