import { useCallback } from 'react';

import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { useAuditMutation } from 'services/audit/auditApiService';
import { AuditPropsType } from 'services/audit/auditApiService.types';

import useFullSelectedEmployee from './useFullSelectedEmployee.hook';

type AuditEventKey = {
  SUCCESS: string;
  ERROR: string;
};

type AuditParamsType = Pick<AuditPropsType, 'outcome'> & {
  key: AuditEventKey;
};

export const useAudit = () => {
  const [auditMutation] = useAuditMutation();
  const [selectedEmployee] = useFullSelectedEmployee();

  const audit = useCallback(
    async (params: AuditParamsType) => {
      const { key, outcome } = params;
      let additionalDetails;

      if (Capacitor.getPlatform() !== 'web') {
        const info = await CapacitorApp.getInfo();
        additionalDetails = {
          mobileVersion: info.version,
        };
      }

      auditMutation({
        subject: selectedEmployee?.id ?? 'employee',
        subjectId: selectedEmployee?.id,
        outcome,
        description: key[outcome],
        additionalDetails,
      });
    },
    [auditMutation, selectedEmployee?.id],
  );

  const auditSuccess = useCallback(
    (key: AuditEventKey) => {
      audit({
        outcome: 'SUCCESS',
        key,
      });
    },
    [audit],
  );

  const auditError = useCallback(
    (key: AuditEventKey) => {
      audit({
        outcome: 'ERROR',
        key,
      });
    },
    [audit],
  );

  return {
    auditError,
    auditSuccess,
  };
};
