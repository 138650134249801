import InternalHeader from './header';
import HeaderGoBack from './header-go-back';
import HeaderGoBackButton from './header-go-back-button';
import HeaderLabel from './header-label';
import HeaderLogo from './header-logo';
import HeaderNotification from './header-notification';
import HeaderRightIcon from './header-right-icon';

type CompoundedComponents = typeof InternalHeader & {
  Label: typeof HeaderLabel;
  GoBack: typeof HeaderGoBack;
  GoBackButton: typeof HeaderGoBackButton;
  Logo: typeof HeaderLogo;
  Notification: typeof HeaderNotification;
  RightIcon: typeof HeaderRightIcon;
};

const Header = InternalHeader as CompoundedComponents;

Header.Label = HeaderLabel;
Header.GoBack = HeaderGoBack;
Header.GoBackButton = HeaderGoBackButton;
Header.Logo = HeaderLogo;
Header.Notification = HeaderNotification;
Header.RightIcon = HeaderRightIcon;

export default Header;
