import { FC } from 'react';

import { Link } from 'react-router-dom';
import { HOME_ROUTE } from 'routes/home/list';

import { ReactComponent as LogoDefault } from 'assets/logo/logo-short-name-row-blue.svg';

import s from './HeaderLogo.module.scss';

interface HeaderLogoProps {
  useNavigation?: boolean;
}

const HeaderLogo: FC<HeaderLogoProps> = (props) => {
  const { useNavigation = true } = props;

  return (
    <Link to={useNavigation ? HOME_ROUTE : ''} className={s.wrapper}>
      <LogoDefault />
    </Link>
  );
};

export default HeaderLogo;
