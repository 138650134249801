import { createSlice } from '@reduxjs/toolkit';
import { ChildrenCouponType } from 'gazprom-common-lib';

export const SLICE_NAME = 'promocodesSlice';

export type PromocodesSliceState = {
  selectedCoupon: ChildrenCouponType | null;
};

const initialState: PromocodesSliceState = {
  selectedCoupon: null,
};

export const promocodesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSelectedCoupon(state, action) {
      state.selectedCoupon = action.payload;
    },
    resetSelectedCoupon(state) {
      state.selectedCoupon = null;
    },
  },
});

export const { setSelectedCoupon, resetSelectedCoupon } = promocodesSlice.actions;

export default promocodesSlice.reducer;
