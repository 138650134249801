import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import s from '../Card.module.scss';

interface CardNavigateProps extends PropsWithChildren {
  to: string;
  className?: string;
  withPadding?: boolean;
  navigationState?: Record<string, unknown>;
}

const CardNavigate: FC<CardNavigateProps> = (props) => {
  const { className, to, children, withPadding = true, navigationState = {} } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(to, { state: { ...navigationState, ...location?.state } });
  };

  return (
    <button
      onClick={onClick}
      className={cn(s.wrapper, className, { [s.withPadding]: withPadding })}>
      {children}
    </button>
  );
};

export default CardNavigate;
