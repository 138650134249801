import { Divider as ADivider } from 'antd';
import cn from 'classnames';

import s from './divider.module.scss';

interface DividerProps {
  className?: string;
}

const Divider = (props: DividerProps) => {
  const { className } = props;
  return <ADivider className={cn(s.divider, className)} />;
};

export default Divider;
