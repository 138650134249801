import { useMemo } from 'react';

import { EmployeeType } from 'gazprom-common-lib';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { getEmployeesSelector } from 'services/me/meApiService';
import { useAppSelector } from 'store';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

const useFullSelectedEmployee = (): [EmployeeType | null, (id: string) => void] => {
  const { data } = useAppSelector(getEmployeesSelector);
  const [selectedEmployeeId, setSelectedEmployee] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SELECTED_EMPLOYEE,
    '',
  );

  const selectedEmployee = useMemo(() => {
    return data?.find((e) => e.id === selectedEmployeeId);
  }, [data, selectedEmployeeId]);

  return [selectedEmployee ?? null, setSelectedEmployee];
};

export default useFullSelectedEmployee;
