import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import ru from 'locales/ru.json';
import { Trans, initReactI18next } from 'react-i18next';

const resources = {
  ru,
};

const FALLBACK_LANGUAGE = 'ru';

i18n.use(initReactI18next).use(LngDetector).init({
  fallbackLng: FALLBACK_LANGUAGE,
  resources,
});

export const t = (
  key: string,
  params?: Record<string, string | number | boolean | undefined>,
): string => i18n.t(key, params || {});

export { Trans };

export default i18n;
